import React, { useEffect, useRef, useState, KeyboardEvent } from "react";
import "./Chat.css";
import { FaPhoneAlt } from "react-icons/fa";
import { FaVideo } from "react-icons/fa6";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { MdEmojiEmotions } from "react-icons/md";
import { FaMicrophone } from "react-icons/fa";
import { AiOutlinePicture } from "react-icons/ai";
import EmojiPicker from "emoji-picker-react";
import { axiosPrivate } from "../../../configuration/axiosConfig.ts";
import { useUser } from "../../ContextProviders/UserContext.tsx"; // Adjust import path
import { RxAvatar } from "react-icons/rx";
import { useWebSocket } from "../../ContextProviders/WebSocketContext.tsx";
import imageCompression from "browser-image-compression";

interface ChatProps {
  conversationID: number | null;
  contactID: number | null;
  contactUsername: string | null;
  onUpdateChatListFlag: () => void;
  setSharedImages?: (url: cachedImages[]) => void; // Optional property for shared images
}

interface Message {
  type: string;
  id: number;
  sender_id: number;
  contact_id: number | null;
  conversation_id: number | null;
  content: string;
  attachment_id?: number; // Optional attachment ID
  image_url?: string; // URL of associated image
  image_data?: string; // Base64 encoded image data (optional)
  sent_at: string;
}

interface cachedImages {
  imageData: string | undefined;
  date: string;
}

const Chat: React.FC<ChatProps> = ({ conversationID, contactID, contactUsername, onUpdateChatListFlag, setSharedImages }) => {
  const { userID } = useUser(); // Use the context here
  const [open, setOpen] = useState(false);
  const [text, setText] = useState("");
  const [messages, setMessages] = useState<Message[]>([]);
  const [contactProfileImage, setContactProfileImage] = useState<string>(); // Initialize as needed
  const { socket, sendMessage } = useWebSocket();
  const socketRef = useRef<WebSocket | null>(socket);
  const endRef = useRef<HTMLDivElement>(null);
  const [firstMessages, setFirstMessages] = useState<Message[]>([]);
  const [localConversationID, setLocalConversationID] = useState<number | null>(0);
  const [images, setImages] = useState<File[]>([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [chatLoading, setChatLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const chatContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const cachedImages = messages
      .filter(message => message.image_data)
      .map(message => ({
        imageData: message.image_data,
        date: message.sent_at,
      }));

    setSharedImages(cachedImages);
  }, [messages, setSharedImages]);

  useEffect(() => {
    setMessages([]);
    setChatLoading(true);
  }, [conversationID]);

  useEffect(() => {
    setLocalConversationID(conversationID);
    if (conversationID !== null) {
      fetchMessages(conversationID, 20);
    } else if (conversationID === 0) {
      setFirstMessages([]);
      setMessages([]);
    }
  }, [conversationID]);

  useEffect(() => {
    if (contactID !== null) {
      fetchContactImage(contactID);
    } else {
      setContactProfileImage(undefined);
    }
  }, [contactID]);

  useEffect(() => {
    setHasMore(true);
  }, [conversationID]);

  useEffect(() => {
    const chatContainer = chatContainerRef.current;

    const handleScroll = () => {
      if (chatContainer) {
        const isAtTop = chatContainer.scrollTop === 0;
        if (isAtTop) {
          loadMoreMessages();
        }
      }
    };

    if (chatContainer) {
      chatContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (chatContainer) {
        chatContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [conversationID, messages, loading, hasMore]);

  useEffect(() => {
    autoScrollToChatBottom();
  }, [messages, conversationID]);

  useEffect(() => {
    socketRef.current = socket; // Update the ref whenever socket changes

    const handleMessage = (event: MessageEvent) => {
      onUpdateChatListFlag();
      try {
        const newMessage: Message = JSON.parse(event.data);

        if (newMessage.sender_id === contactID) {
          setMessages(prevMessages => {
            // Check the previous state here
            if (!prevMessages || prevMessages.length === 0) {
              return [newMessage];
            } else {
              return [...prevMessages, newMessage];
            }
          });
        } else {
          return;
        }
      } catch (error) {
        console.error("Error parsing message", error);
      }
    };

    if (socket) {
      socket.onmessage = handleMessage;
    }

    // Cleanup function to remove event listeners when component unmounts
    return () => {
      if (socket) {
        socket.onmessage = null;
      }
    };
  }, [socket, contactID]); // Depend on socket and onUpdateChatListFlag to handle changes

  const fetchContactImage = async (contactID: number) => {
    try {
      const response = await axiosPrivate.get(`/api/get-profile-photo?userId=${contactID}`);
      if (response.data.imageUrl) {
        setContactProfileImage(response.data.imageUrl);
      } else {
        setContactProfileImage(undefined);
      }
    } catch (error) {
      console.error("Error fetching profile image:", error);
    }
  };

  const fetchMessages = async (conversationID: number | null, limit: number = 10, earliestMessageID?: number) => {
    try {
      // Prepare parameters for the request
      const params: { [key: string]: any } = {
        conversationID,
        limit,
      };

      if (earliestMessageID) {
        params.last_message_id = earliestMessageID;
      }
      // await new Promise(resolve => setTimeout(resolve, 30000)); // 1-second delay

      const response = await axiosPrivate.get(`/api/get-conversation-messages`, { params });

      // Check if the response data is null or empty
      const newMessages = response.data === null ? [] : response.data;

      if (newMessages.length < limit) {
        setHasMore(false); // No more messages to load
      }

      // If there are new messages, update the state
      if (earliestMessageID) {
        // Append new messages if it's a load more request
        setMessages(prevMessages => [...newMessages, ...prevMessages]);
      } else {
        // Set initial messages if it's the first load
        setFirstMessages(newMessages);
        setMessages(newMessages);
      }
      setChatLoading(false);

      // Log response for debugging
    } catch (error) {
      console.error("Failed to fetch messages", error);
    }
  };

  const loadMoreMessages = async () => {
    if (loading || !hasMore) return;

    setLoading(true);

    try {
      const earliestMessage = messages[0];

      if (earliestMessage) {
        await fetchMessages(localConversationID, 10, earliestMessage.id);
      }
    } catch (error) {
      console.error("Failed to fetch more messages", error);
    } finally {
      setLoading(false);
    }
  };

  const sendImageMessages = async (conversationID: number | null) => {
    if (conversationID === null) {
      return;
    }
    const latestMessageID = !messages || messages.length === 0 ? 0 : messages[messages.length - 1]?.id || 0;

    // Prepare FormData to send all images in one request
    const formData = new FormData();
    formData.append("user_id", userID.toString());
    formData.append("conversation_id", conversationID.toString());
    if (contactID !== undefined && contactID !== null) {
      formData.append("contact_id", contactID.toString());
    }

    // Initialize an incrementing counter
    let i = 1;

    // Append images with incremented id
    images.forEach(image => {
      if (latestMessageID !== undefined && latestMessageID !== null) {
        formData.append("id", (latestMessageID + i).toString()); // Append incremented ID
      }
      formData.append(`image`, image); // Append the image itself
      i++; // Increment the counter
    });

    try {
      const response = await axiosPrivate.post("/api/upload-chat-images", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      // Update the messages state with the new messages received from the server
      const newMessages = response.data.messages;
      setMessages(prevMessages => [...(prevMessages || []), ...newMessages]);
      newMessages.forEach(message => {
        sendMessage(message);
      });

      // Clear images after sending
      setImages([]);
      autoScrollToChatBottom();
    } catch (error) {
      console.error("Failed to send images", error);
    }
  };

  const handleSendMessage = async (text: string) => {
    if (!socket) {
      console.error("WebSocket is not available");
      return;
    }

    if (text === "" && images.length === 0) {
      return;
    }

    if (firstMessages.length === 0) {
      // console.log("This is the first message");
      setFirstMessages([
        {
          type: "string",
          id: 0,
          sender_id: 0,
          contact_id: null,
          conversation_id: 0,
          content: "string",
          sent_at: "string",
        },
      ]);
      try {
        const response = await axiosPrivate.get(`/api/init-conversation`, {
          params: { u: userID, cu: contactID },
        });
        const data = response.data;
        setLocalConversationID(data.conversationID);
        if (text !== "") {
          const latestMessageID = !messages || messages.length === 0 ? 0 : messages[messages.length - 1]?.id || 0;
          const newMessage: Message = {
            type: "message",
            id: latestMessageID + 1,
            sender_id: userID,
            contact_id: contactID,
            conversation_id: data.conversationID,
            content: text,
            sent_at: new Date().toISOString(),
          };

          // Update messages state
          setMessages([newMessage]);
          sendMessage(newMessage);

          setText(""); // Clear input field
        }

        if (images.length > 0) {
          await sendImageMessages(data.conversationID); // Send images after text message
        }
        onUpdateChatListFlag();
      } catch (error) {
        console.error("Failed to fetch messages", error);
      }
    } else {
      // console.log("This is NOT the first message");

      if (text !== "") {
        const latestMessageID = !messages || messages.length === 0 ? 0 : messages[messages.length - 1]?.id || 0;
        const newMessage: Message = {
          type: "message",
          id: latestMessageID + 1,
          sender_id: userID,
          contact_id: contactID,
          conversation_id: localConversationID,
          content: text,
          sent_at: new Date().toISOString(),
        };

        // Update messages state
        setMessages(prevMessages => [...(prevMessages || []), newMessage]);

        sendMessage(newMessage);

        setText(""); // Clear input field
      }

      if (images.length > 0) {
        await sendImageMessages(localConversationID); // Send images after text message
      }
      onUpdateChatListFlag();
    }
  };

  const autoScrollToChatBottom = () => {
    const chatContainer = document.querySelector(".center");
    if (chatContainer) {
      const previousScrollHeight = chatContainer.scrollHeight;

      // Delay the scrollIntoView by 50 milliseconds
      setTimeout(() => {
        const chatContainer = document.querySelector(".center");
        if (chatContainer) {
          const isAtBottom = previousScrollHeight - chatContainer.scrollTop <= chatContainer.clientHeight + 100;
          if (isAtBottom) {
            endRef.current?.scrollIntoView({ behavior: "smooth" });
          }
        }
      }, 100);
    }
  };

  const handleInputChange = e => {
    setText(e.target.value);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSendMessage(e.currentTarget.value); // Use e.currentTarget instead of e.target
      setText(""); // Clear input field after sending
    }
  };

  const handleEmoji = e => {
    setText(prev => prev + e.emoji);
  };

  const formatDate = isoString => {
    const date = new Date(isoString);

    // Define the options for date formatting
    const dateOptions: Intl.DateTimeFormatOptions = {
      year: "numeric", // 'numeric' or '2-digit'
      month: "2-digit", // 'numeric' or '2-digit'
      day: "2-digit", // 'numeric' or '2-digit'
    };

    // Define the options for time formatting
    const timeOptions: Intl.DateTimeFormatOptions = {
      hour: "2-digit", // 'numeric' or '2-digit'
      minute: "2-digit", // 'numeric' or '2-digit'
    };
    // Format date and time separately
    const formattedDate = date.toLocaleDateString("en-US", dateOptions);
    const formattedTime = date.toLocaleTimeString("en-US", timeOptions);

    // Combine formatted date and time
    return `${formattedDate}, ${formattedTime}`;
  };

  const compressImage = async (file: File) => {
    const options = {
      maxSizeMB: 1, // Maximum size in MB
      maxWidthOrHeight: 1024, // Max width or height
      minWidthOrHeight: 400,
      useWebWorker: true, // Use web workers for better performance
      fileType: "image/jpeg", // Output file type
    };

    try {
      const compressedFile = await imageCompression(file, options);
      return compressedFile;
    } catch (error) {
      console.error("Error compressing the image", error);
      return file; // Fallback to original file if compression fails
    }
  };

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const handleAddImages = async (files: FileList) => {
    const fileArray = Array.from(files); // Convert FileList to an array
    const compressedFiles = await Promise.all(fileArray.map(file => compressImage(file))); // Compress each image
    setImages(prevImages => [...prevImages, ...compressedFiles]);
  };

  const handleRemoveImage = (index: number) => {
    setImages(prevImages => prevImages.filter((_, i) => i !== index));
  };

  const handleFileChange = event => {
    const files = event.target.files;
    if (files) {
      handleAddImages(files);
      togglePopup(); // Close the popup after selecting images
    }
  };

  // Render image based on available data
  const renderImage = (image_url: string | undefined, image_data: string | undefined) => {
    if (image_data) {
      // Display base64 encoded image data
      return <img src={`data:image/jpeg;base64,${image_data}`} alt="Message attachment" className="message-image" />;
    } else if (image_url) {
      // Fallback to URL if no image data
      return <img src={image_url} alt="Message attachment" className="message-image" />;
    }
    return null;
  };

  return (
    <div className="chat">
      <div className="top">
        <div className="user">
          {contactProfileImage ? <img src={contactProfileImage} className="contactImg" /> : <RxAvatar className="avatar-icon" />}
          <div className="texts">
            <div className="chat-contact-username">{contactUsername}</div>
          </div>
        </div>
        {/* <div className="icons-chat-top">
          <FaPhoneAlt className="icon-chat-top"></FaPhoneAlt>
          <FaVideo className="icon-chat-top"></FaVideo>
          <IoMdInformationCircleOutline className="icon-chat-top"></IoMdInformationCircleOutline>
        </div> */}
      </div>
      <div className="center" ref={chatContainerRef}>
        {loading && (
          <div className="loading-indicator">
            <div className="spinner"></div>
          </div>
        )}
        {chatLoading && (
          <div className="chat-loading-indicator">
            <div className="chat-spinner"></div>
          </div>
        )}
        {messages?.map((message, index) => {
          const nextMessage = messages[index + 1];
          const isSameSender = nextMessage?.sender_id === message.sender_id;

          // Convert Date objects to milliseconds using getTime()
          const currentMessageTime = new Date(message.sent_at).getTime();
          const nextMessageTime = nextMessage ? new Date(nextMessage.sent_at).getTime() : null;

          // Time difference in minutes
          const timeDifference = nextMessageTime !== null ? (nextMessageTime - currentMessageTime) / 1000 / 60 : null;

          // Show date if sender changes or time difference is more than 1 minute
          const showDate = !isSameSender || (timeDifference !== null && timeDifference > 1);

          return (
            <div key={message.id} className={`message ${message.sender_id === userID ? "own" : ""}`}>
              {/* Conditionally render the profile image or a placeholder based on whether the next message is from the same sender */}
              {message.sender_id === userID ? null : !isSameSender && contactProfileImage ? (
                <img src={contactProfileImage} alt="Contact Profile" className="contactImg" />
              ) : !isSameSender ? (
                <RxAvatar className="avatar-icon" />
              ) : (
                // Display an empty div with the same dimensions as the image when not showing the image
                <div className="empty-placeholder"></div>
              )}

              <div className="texts">
                <div className="contactText">
                  <div className="chat-image-container">
                    {renderImage(message.image_url, message.image_data)}{" "}
                    {message.content && (
                      <p className={`${isSameSender && (timeDifference === null || timeDifference <= 1) ? "consecutive-message" : "separate-message"}`}>
                        {message.content}
                      </p>
                    )}
                  </div>
                </div>

                {/* Conditionally show the date if the next message is more than 1 minute apart */}
                {showDate && (
                  <span className={`${isSameSender && (timeDifference === null || timeDifference <= 1) ? "consecutive-date" : "separate-date"}`}>
                    {formatDate(message.sent_at)}
                  </span>
                )}
              </div>
            </div>
          );
        })}
        <div className="end-ref" ref={endRef}></div>
      </div>

      <div className="images-preview">
        {images.map((image, index) => (
          <div key={index} className="image-container">
            <img src={URL.createObjectURL(image)} alt="preview" />
            <button className="delete-button" onClick={() => handleRemoveImage(index)}>
              &times;
            </button>
          </div>
        ))}
      </div>
      <div className="bottom">
        {isPopupOpen && (
          <div className="popup">
            <div className="popup-content">
              <div className="select-images">Select Images</div>
              <div className="pop-up-row-2">
                <input type="file" accept="image/jpeg, image/png, image/gif, image/webp, image/svg+xml" multiple onChange={handleFileChange} />
                <button className="close-popup" onClick={togglePopup}>
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
        <div className="icons-chat-bot">
          <AiOutlinePicture onClick={togglePopup} className="icon-chat-bot" style={{ cursor: "pointer" }}></AiOutlinePicture>
          <FaVideo className="icon-chat-bot"></FaVideo>
          <FaMicrophone className="icon-chat-bot"></FaMicrophone>
        </div>
        <input type="text" placeholder="Type a message..." value={text} onChange={handleInputChange} onKeyDown={handleKeyDown} />{" "}
        <div className="emoji">
          <MdEmojiEmotions className="emojiIcon" style={{ fontSize: "1.5em" }} onClick={() => setOpen(prev => !prev)} />
          <div className="picker">
            <EmojiPicker open={open} onEmojiClick={handleEmoji} searchPlaceHolder="" />
          </div>
        </div>
        <button className="sendButton" onClick={() => handleSendMessage(text)}>
          Send
        </button>{" "}
      </div>
    </div>
  );
};

export default Chat;
