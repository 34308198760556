import React, { useState, useEffect, useCallback } from "react";
import "./SearchSection.css";
import { FaSearch } from "react-icons/fa";
import { IoPersonAdd } from "react-icons/io5";
import FriendRequestsModal from "../../../Modals/FriendRequest/FriendRequestsModal.tsx";
import { handleDeleteFriendRequest, handleAcceptFriendRequest } from "../../../../handlers/ChatPageHandlers.tsx";
import { axiosPrivate } from "../../../../configuration/axiosConfig.ts";
import { useUser } from "../../../ContextProviders/UserContext.tsx";
import { debounce } from "../../../../utils/debounce.js";

interface FriendRequest {
  id: number; // Unique identifier for the friend request
  username: string; // Username of the requester
  profilePicture: string; // URL of the requester's profile picture
}

const SearchSection = ({ onSearchChange, setSearchDebounceLoading }) => {
  const { userID } = useUser(); // Use the context here
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [friendRequests, setFriendRequests] = useState<FriendRequest[]>([]);
  const [searchQuery, setSearchQuery] = useState(""); // Local state for search query

  // Debounced version of the onSearchChange function
  const debouncedSearch = useCallback(
    debounce(async query => {
      setSearchDebounceLoading(true); // Set loading to true before starting the search

      try {
        await onSearchChange(query); // Call the onSearchChange function
      } catch (error) {
        console.error("Error in onSearchChange:", error);
      } finally {
        setSearchDebounceLoading(false); // Ensure loading is set to false after the search completes
      }
    }, 560),
    [onSearchChange]
  );

  useEffect(() => {
    // Call debounced function whenever searchQuery changes
    debouncedSearch(searchQuery);
  }, [searchQuery, debouncedSearch]);

  useEffect(() => {
    const fetchFriendRequests = async () => {
      try {
        const response = await axiosPrivate.get("/api/get-friend-requests", {
          params: { userId: userID },
        });
        setFriendRequests(response.data);
      } catch (error) {
        console.error("Error fetching friend requests:", error);
      }
    };

    if (userID) {
      fetchFriendRequests();
    }
  }, [userID]);

  const handleInputChange = event => {
    setSearchQuery(event.target.value); // Update local search query
  };

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleAccept = async (id: number) => {
    try {
      const result = await handleAcceptFriendRequest(id, userID);
      if (result) {
        setFriendRequests(prev => prev.filter(request => request.id !== id));
      } else {
        console.error("Failed to delete friend request");
      }
    } catch (error) {
      console.error("Error deleting friend request:", error);
    }
  };

  const handleDelete = async (id: number) => {
    try {
      const result = await handleDeleteFriendRequest(id);
      if (result) {
        setFriendRequests(prev => prev.filter(request => request.id !== id));
      } else {
        console.error("Failed to delete friend request");
      }
    } catch (error) {
      console.error("Error deleting friend request:", error);
    }
  };

  const handleBlock = (id: number) => {
    setFriendRequests(prev => prev.filter(request => request.id !== id));
  };

  return (
    <div className="search">
      <div className="searchBar">
        <FaSearch className="searchImg" />
        <input type="text" onChange={handleInputChange} placeholder="Search" value={searchQuery} />
      </div>
      <div className="addIcon">
        <IoPersonAdd className="plusImg" onClick={handleOpenModal} />
        <div className="friend-number">{friendRequests?.length > 0 && <div className="badge">{friendRequests?.length}</div>}</div>
        <FriendRequestsModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          friendRequests={friendRequests}
          onAccept={handleAccept}
          onDelete={handleDelete}
          onBlock={handleBlock}
        />
      </div>
    </div>
  );
};

export default SearchSection;
