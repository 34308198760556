import React, { useState, useEffect } from "react";
import Chat from "./Chat/Chat.tsx";
import List from "./List/List.tsx";
import Details from "./Details/Details.tsx";
import "./ChatPage.css";
import { UserProvider } from "./../ContextProviders/UserContext.tsx";
import { axiosPrivate } from "../../configuration/axiosConfig.ts";
import { WebSocketProvider } from "../ContextProviders/WebSocketContext.tsx";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import { RiCollapseDiagonalLine, RiExpandDiagonalLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

interface ConversationResponse {
  conversation_id: number;
  contact_id: number;
  contact_username: string;
}

interface cachedImage {
  imageData: string | undefined;
  date: string;
}

const ChatPage = () => {
  const [userID, setUserID] = useState<number | undefined>();
  const [latestConversation, setLatestConversation] = useState<ConversationResponse>();
  const [currentConversationID, setCurrentConversationID] = useState<number | null>(null);
  const [currentContactID, setCurrentContactID] = useState<number | null>(null);
  const [currentContactUsername, setCurrentContactUsername] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isDetailsCollapsed, setIsDetailsCollapsed] = useState(false);
  const [updateChatListFlag, setUpdateChatListFlag] = useState(false);
  const [sharedImages, setSharedImages] = useState<cachedImage[]>([]);

  // New states to manage views on small screens
  const [showList, setShowList] = useState(true);
  const [showChat, setShowChat] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  const handleUpdateCurrentConversationID = (conversationID: number) => {
    setCurrentConversationID(conversationID);
  };

  const handleUpdateCurrentContactID = (contactID: number) => {
    setCurrentContactID(contactID);
  };

  const handleUpdateCurrentContactUsername = (username: string) => {
    setCurrentContactUsername(username);
  };

  const handleUpdateChatListFlag = () => {
    setUpdateChatListFlag(prevFlag => !prevFlag);
  };

  useEffect(() => {
    const handleBackButton = (event: PopStateEvent) => {
      event.preventDefault(); // Prevent default navigation behavior

      if (showDetails) {
        // Go from Details to Chat
        setShowDetails(false);
        setShowChat(true);
        setShowList(false);
        localStorage.setItem("currentView", "chat"); // Save current view
      } else if (showChat) {
        // Go from Chat to List
        setShowChat(false);
        setShowList(true);
        setShowDetails(false);
        localStorage.setItem("currentView", "list"); // Save current view
      }
    };

    const pushCurrentState = () => {
      window.history.pushState(null, "", window.location.href);
    };

    // Push new state when the user navigates between views
    if (showChat || showDetails) {
      pushCurrentState();
    }

    // Listen to popstate event (triggered by the back button)
    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [showChat, showDetails, showList]); // Ensure it runs whenever view changes

  useEffect(() => {
    const savedView = localStorage.getItem("currentView");
    if (savedView === "chat") {
      setShowChat(true);
      setShowList(false);
      setShowDetails(false);
    } else if (savedView === "details") {
      setShowDetails(true);
      setShowList(false);
      setShowChat(false);
    } else {
      // Default to list view if no view is saved
      setShowList(true);
      setShowChat(false);
      setShowDetails(false);
    }
  }, []);

  useEffect(() => {
    const fetchUserID = async () => {
      try {
        const response = await axiosPrivate.get("/api/getUserID", {});
        setUserID(response.data.userID);
      } catch (error) {
        console.error("Failed to fetch userID", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserID();
  }, []);

  useEffect(() => {
    const fetchLatestConversation = async () => {
      if (!userID) return;

      try {
        const response = await axiosPrivate.get("/api/get-latest-conversation", {
          params: { userId: userID },
        });
        setLatestConversation(response.data);
      } catch (err) {
        setError("Error fetching latest conversation");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchLatestConversation();
  }, [userID]);

  useEffect(() => {
    if (latestConversation) {
      setCurrentConversationID(latestConversation.conversation_id);
      setCurrentContactID(latestConversation.contact_id);
      setCurrentContactUsername(latestConversation.contact_username);
    }
  }, [latestConversation]);

  const toggleDetails = () => {
    setIsDetailsCollapsed(prev => !prev);
  };

  // Functions for small screens to switch views
  const showChatView = () => {
    setShowList(false);
    setShowChat(true);
    setShowDetails(false);
    localStorage.setItem("currentView", "chat"); // Save current view
  };

  const showDetailsView = () => {
    setShowList(false);
    setShowChat(false);
    setShowDetails(true);
    localStorage.setItem("currentView", "details"); // Save current view
  };

  const showListView = () => {
    setShowList(true);
    setShowChat(false);
    setShowDetails(false);
    localStorage.setItem("currentView", "list"); // Save current view
  };

  return (
    <div className={`chatPage ${showChat ? "show-chat" : ""} ${showDetails ? "show-details" : ""}`}>
      <UserProvider>
        <WebSocketProvider>
          {/* List View */}
          <div className={`list ${showList ? "" : "hide-page"}`}>
            <List
              updateChatListFlag={updateChatListFlag}
              onCurrentConversationIDChange={handleUpdateCurrentConversationID}
              onCurrentContactIDChange={handleUpdateCurrentContactID}
              onCurrentContactUsernameChange={handleUpdateCurrentContactUsername}
              onSelectConversation={showChatView}
            ></List>
            {/* Button for showing chat (only on small screens) */}
            {!showChat && (
              <button className="toggle-button chat-button" onClick={showChatView}>
                <RiArrowRightSLine size={25} />
              </button>
            )}
          </div>

          {/* Chat View */}
          <div className={`chat ${isDetailsCollapsed ? "expanded" : ""} ${showChat ? "" : "hide-page"}`}>
            <Chat
              onUpdateChatListFlag={handleUpdateChatListFlag}
              conversationID={currentConversationID}
              contactID={currentContactID}
              contactUsername={currentContactUsername}
              setSharedImages={setSharedImages}
            ></Chat>
            {/* Button for returning to the list */}
            <button className="toggle-button list-button" onClick={showListView}>
              <RiArrowLeftSLine size={25} />
            </button>
            {/* Button for showing details (only on small screens) */}
            {!showDetails && (
              <button className="toggle-button details-button" onClick={showDetailsView}>
                <RiArrowRightSLine size={25} />
              </button>
            )}
          </div>

          {/* Details View */}
          <div className={`details ${isDetailsCollapsed ? "collapsed" : ""} ${showDetails ? "" : "hide-page"}`}>
            <Details
              conversationID={currentConversationID}
              contactID={currentContactID}
              contactUsername={currentContactUsername}
              sharedImages={sharedImages}
            ></Details>
            {/* Button for returning to chat */}
            <button className="toggle-button list-button" onClick={showChatView}>
              <RiArrowLeftSLine size={25} />
            </button>
            <button className="toggle-button-expand" onClick={toggleDetails}>
              {isDetailsCollapsed ? <RiExpandDiagonalLine color="#000" size={25} /> : <RiCollapseDiagonalLine color="#000" size={25} />}
            </button>
          </div>
        </WebSocketProvider>
      </UserProvider>
    </div>
  );
};

export default ChatPage;
