// FriendRequestsModal.tsx
import React from "react";
import "./FriendRequestsModal.css";
import { FaXmark } from "react-icons/fa6";

interface FriendRequest {
  id: number; // Unique identifier for the friend request
  username: string; // Username of the requester
  profilePicture: string; // URL of the requester's profile picture
}

interface FriendRequestsModalProps {
  isOpen: boolean;
  onClose: () => void;
  friendRequests: FriendRequest[];
  onAccept: (id: number) => void;
  onDelete: (id: number) => void;
  onBlock: (id: number) => void;
}

const FriendRequestsModal: React.FC<FriendRequestsModalProps> = ({ isOpen, onClose, friendRequests, onAccept, onDelete, onBlock }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal-header">
          <div className="friend-request">Friend Requests</div>
          <button onClick={onClose} className="close-button">
            <FaXmark />
          </button>
        </div>
        <div className="modal-body">
          {friendRequests?.length > 0 ? (
            friendRequests.map(request => (
              <div key={request.id} className="friend-request-item">
                <img src={request.profilePicture} className="profile-picture" />
                <div className="request-username">{request.username}</div>
                <div className="friend-request-actions">
                  <button onClick={() => onAccept(request.id)}>Accept</button>
                  <button onClick={() => onDelete(request.id)}>Delete</button>
                  <button onClick={() => onBlock(request.id)}>Block</button>
                </div>
              </div>
            ))
          ) : (
            <div className="no-friend-request">No friend requests</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FriendRequestsModal;
