import React, { useState, useEffect } from "react";
import { RxAvatar } from "react-icons/rx";
import "./ProfileModal.css"; // Ensure your CSS file is correctly referenced
import { axiosPrivate } from "../../../configuration/axiosConfig.ts";
import { useUser } from "../../ContextProviders/UserContext.tsx";
import { FaXmark } from "react-icons/fa6";

// Define props types for the ProfileModal component
interface ProfileModalProps {
  isOpen: boolean;
  onClose: () => void;
  onUploadSuccess: (imageUrl: string) => void; // Adjust this if needed
}

const ProfileModal: React.FC<ProfileModalProps> = ({ isOpen, onClose, onUploadSuccess }) => {
  const { userID, profileImage } = useUser(); // Ensure userID is available from context
  const [tempProfileImage, setTempProfileImage] = useState<string | null>(profileImage);

  useEffect(() => {
    setTempProfileImage(profileImage);
  }, [profileImage]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setTempProfileImage(URL.createObjectURL(file)); // Create a local URL for the file
    }
  };

  const handleSubmit = async () => {
    if (!tempProfileImage) return;

    const fileInput = document.querySelector('input[type="file"]') as HTMLInputElement;
    const formData = new FormData();
    const file = fileInput.files?.[0];
    if (file) {
      formData.append("profilePhoto", file);
      formData.append("userId", userID.toString()); // Replace with the actual user ID

      try {
        const response = await axiosPrivate.post("/api/upload-profile-photo", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (response.status === 200) {
          try {
            const response = await axiosPrivate.get(`/api/get-profile-photo?userId=${userID}`);
            if (response.data.imageUrl) {
              onUploadSuccess(response.data.imageUrl);
              onClose(); // Close the modal after successful upload
            }
          } catch (error) {
            console.error("Error fetching profile image:", error);
          }
        } else {
          console.error("Upload failed");
        }
      } catch (error) {
        console.error("Upload error:", error);
      }
    }
  };

  const handleButtonClick = () => {
    onClose(); // Close the modal
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content-profile-picture">
        <button className="profile-modal-close-user" onClick={onClose}>
          <FaXmark />
        </button>
        <div className="r1-modal">
          <div className="upload-picture">Upload Profile Picture</div>
        </div>
        <div className="r2-modal">
          <div className="c1-modal">
            {tempProfileImage ? <img src={tempProfileImage} alt="Selected file preview" className="preview-image" /> : <RxAvatar className="avatar-icon" />}
            <input className="input-profile" type="file" accept="image/jpeg" onChange={handleFileChange} />
          </div>
          <div className="c2-modal">
            <button className="sbutton-modal" onClick={handleSubmit}>
              {"Upload"}
            </button>
            {/* <button className="cbutton-modal" onClick={handleButtonClick}>
              Cancel
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileModal;
