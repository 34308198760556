import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { ThemeProvider, CssBaseline } from "@mui/material";
// import ChatPage from "./components/ChatPage/ChatPage.tsx";
import LoginPage from "./components/LoginPage/LoginPage.tsx";
import LoginForm from "./components/LoginPage/LoginForm/LoginForm.tsx";
import ChatPage from "./components/ChatPage/ChatPage.tsx";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute.tsx";
import RegistrationForm from "./components/LoginPage/RegistrationForm/RegistrationForm.tsx";
import ForgotPassForm from "./components/LoginPage/ForgotPassForm/ForgotPassForm.tsx";
import theme from "./theme.tsx";
import "./App.css";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path="/" element={<LoginPage />}>
            <Route index element={<LoginForm />} />
            <Route path="register" element={<RegistrationForm />} />
            <Route path="forgot-password" element={<ForgotPassForm />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path="/chat" element={<ChatPage />} />
          </Route>
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
