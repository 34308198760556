import React, { useState, useEffect, useRef } from "react";
import "./ChatList.css";
import { handlePersonSearch, handleGetConversationsInfo, handleAddFriend } from "../../../../handlers/ChatPageHandlers.tsx";
import { useUser } from "../../../ContextProviders/UserContext.tsx"; // Adjust import path
import SuccessModal from "../../../Modals/Registration/SuccessModal.tsx";

interface User {
  id: number;
  username: string;
  profile_picture: string;
  is_contact: boolean;
}

interface ConversationInfo {
  contact_id: number;
  contact_username: string;
  profile_picture: string;
  user_description: string;
  sender_id: number;
  attachment_id: number;
  last_message: string;
  sent_at: string;
  conversation_id: number;
}

const delay = ms => new Promise(resolve => setTimeout(resolve, ms)); // Utility function for delay

const ChatList = ({
  updateChatListFlag,
  isSearching,
  searchQuery,
  searchDebounceLoading,
  onCurrentConversationChange,
  onCurrentContactChange,
  onCurrentContactUsernameChange,
  onContactClick,
  onContactClickFromContactList,
}: {
  updateChatListFlag: boolean;
  isSearching: boolean;
  searchQuery: string;
  searchDebounceLoading: boolean;
  onCurrentConversationChange: (conversationID: number) => void;
  onCurrentContactChange: (contactID: number) => void;
  onCurrentContactUsernameChange: (contactUsername: string) => void;
  onContactClick: () => void;
  onContactClickFromContactList: () => void;
}) => {
  const { username, userID } = useUser(); // Use the context here
  const [searchResults, setSearchResults] = useState<User[]>([]);
  const [conversationsInfo, setConversationsInfo] = useState<ConversationInfo[]>([]);
  const [loading, setLoading] = useState(false); // Add loading state
  const [showAddSuccessModal, setShowAddSuccessModal] = useState(false); // Modal state
  const socketRef = useRef<WebSocket | null>(null); // Type ref to WebSocket or null

  useEffect(() => {
    const fetchSearchResults = async () => {
      try {
        setLoading(true); // Start loading

        const data = await handlePersonSearch(searchQuery, username);
        setSearchResults(data);
      } catch (err) {
        // Handle error (you can display an error message here)
      } finally {
        setLoading(false); // Stop loading
      }
    };

    if (searchQuery) {
      fetchSearchResults();
    } else {
      setSearchResults([]);
    }
  }, [searchQuery]);

  useEffect(() => {
    const fetchConversationsInfo = async () => {
      try {
        setLoading(true); // Start loading
        // await delay(10000); // 1 second delay for simulation

        const data = await handleGetConversationsInfo(username);
        setConversationsInfo(data);
      } catch (err) {
        // Handle error (you can display an error message here)
      } finally {
        setLoading(false); // Stop loading
      }
    };

    if (username) {
      fetchConversationsInfo();
    } else {
      setConversationsInfo([]);
    }
  }, [username, updateChatListFlag]);

  const updateCurrentConversation = conversationID => {
    onCurrentConversationChange(conversationID);
  };

  const updateCurrentContact = contactID => {
    onCurrentContactChange(contactID);
  };

  const updateCurrentContactUsername = contactUsername => {
    onCurrentContactUsernameChange(contactUsername);
  };

  const lastWordsOfLastMessage = text => {
    if (text.length >= 60) {
      return `${text.slice(0, 60)}...`;
    } else {
      return text;
    }
  };

  const formatDate = isoString => {
    const date = new Date(isoString);

    // Define the options for date formatting
    const dateOptions: Intl.DateTimeFormatOptions = {
      year: "numeric", // 'numeric' or '2-digit'
      month: "2-digit", // 'numeric' or '2-digit'
      day: "2-digit", // 'numeric' or '2-digit'
    };

    // Define the options for time formatting
    const timeOptions: Intl.DateTimeFormatOptions = {
      hour: "2-digit", // 'numeric' or '2-digit'
      minute: "2-digit", // 'numeric' or '2-digit'
    };
    // Format date and time separately
    const formattedDate = date.toLocaleDateString("en-US", dateOptions);
    const formattedTime = date.toLocaleTimeString("en-US", timeOptions);

    // Combine formatted date and time
    return `${formattedDate}, ${formattedTime}`;
  };

  // Function to fetch and filter chats or contacts based on search query
  const getFilteredData = () => {
    if (isSearching) {
      // Fetch and filter contacts and available users based on searchQuery
      // This is a placeholder, replace with actual data-fetching logic
      return (
        <div className="chatList">
          <ul>
            {searchResults?.map(user =>
              user.is_contact ? (
                <li
                  className="searchResult"
                  key={user.id}
                  onClick={() => {
                    const conversation = conversationsInfo?.find(convo => convo.contact_id === user.id);
                    const conversationID = conversation ? conversation.conversation_id : 0;
                    updateCurrentConversation(conversationID);
                    updateCurrentContact(user.id);
                    updateCurrentContactUsername(user.username);
                    onContactClick();
                    onContactClickFromContactList();
                  }}
                >
                  <img src={user.profile_picture ? `data:image/jpeg;base64,${user.profile_picture}` : "./boy.png"} alt="" />
                  <div className="contactUsername">{user.username}</div>
                </li>
              ) : (
                <li className="searchResult" key={user.id}>
                  <img src={user.profile_picture ? `data:image/jpeg;base64,${user.profile_picture}` : "./boy.png"} />
                  <div className="contactUsername">{user.username}</div>
                  <button
                    className="addButton"
                    onClick={async () => {
                      try {
                        await handleAddFriend(userID, user.id);
                        setShowAddSuccessModal(true); // Show modal on success

                        // Call onContactClick() after 2 seconds
                        setTimeout(() => {
                          onContactClick();
                        }, 2000);
                      } catch (error) {
                        // Handle errors if necessary
                        console.error("Failed to add friend:", error);
                      }
                    }}
                  >
                    Add
                  </button>
                </li>
              )
            )}
          </ul>
        </div>
      );
    } else {
      // Fetch and display user's chats
      // This is a placeholder, replace with actual data-fetching logic
      return (
        <div className="chatList">
          <ul>
            {Array.isArray(conversationsInfo) &&
              conversationsInfo
                .sort((a, b) => new Date(b.sent_at).getTime() - new Date(a.sent_at).getTime()) // Sort from latest to oldest
                .map(conversationInfo => (
                  <li key={conversationInfo.contact_id}>
                    <div
                      className="contactResult"
                      onClick={() => {
                        // Ensure conversationInfo and its properties are defined
                        if (conversationInfo && conversationInfo.conversation_id && conversationInfo.contact_id) {
                          updateCurrentConversation(conversationInfo.conversation_id);
                          updateCurrentContact(conversationInfo.contact_id);
                          updateCurrentContactUsername(conversationInfo.contact_username);
                          onContactClickFromContactList();
                        } else {
                          console.error("Invalid conversationInfo:", conversationInfo);
                        }
                      }}
                    >
                      <img src={conversationInfo.profile_picture ? `data:image/jpeg;base64,${conversationInfo.profile_picture}` : "./boy.png"} alt="" />
                      <div className="conversationInfo">
                        <div className="contactUsername">{conversationInfo.contact_username}</div>
                        <div className="lastMessage">
                          {userID === conversationInfo.sender_id ? (
                            <>
                              Me:
                              {conversationInfo.attachment_id !== null && conversationInfo.attachment_id !== 0
                                ? "Sent an image."
                                : lastWordsOfLastMessage(conversationInfo.last_message)}
                            </>
                          ) : (
                            <>
                              {conversationInfo.attachment_id !== null && conversationInfo.attachment_id !== 0
                                ? "Sent an image."
                                : lastWordsOfLastMessage(conversationInfo.last_message)}
                            </>
                          )}

                          <div style={{ fontSize: "0.8em", color: "#888" }}>{formatDate(conversationInfo.sent_at)}</div>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
          </ul>
        </div>
      );
    }
  };

  return (
    <div className="chatList">
      {showAddSuccessModal && (
        <SuccessModal
          message="Friend Request Sent!"
          onClose={() => setShowAddSuccessModal(false)} // Close modal on button click
        />
      )}
      {loading || searchDebounceLoading ? (
        <div className="chatList-loading-indicator">
          <div className="chatList-spinner"></div>
        </div>
      ) : (
        getFilteredData()
      )}
    </div>
  );
};

export default ChatList;
