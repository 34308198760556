import React, { useState, useEffect } from "react";
import "./Details.css";
import { useNavigate } from "react-router-dom";

import { IoMdDownload } from "react-icons/io";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
import { axiosPrivate } from "../../../configuration/axiosConfig.ts";
import { useUser } from "../../ContextProviders/UserContext.tsx"; // Adjust import path
import { RxAvatar } from "react-icons/rx";
import { handleGetDescription } from "../../../handlers/ChatPageHandlers.tsx";

interface ChatProps {
  conversationID: number | null;
  contactID: number | null;
  contactUsername: string | null;
  sharedImages: cachedImage[];
}

interface cachedImage {
  imageData: string | undefined;
  date: string;
}

const Details: React.FC<ChatProps> = ({ conversationID, contactID, contactUsername, sharedImages }) => {
  const { userID } = useUser(); // Use the context here
  const navigate = useNavigate();
  const [contactProfileImage, setContactProfileImage] = useState<string>(); // Initialize as needed
  const [contactDescription, setContactDescription] = useState<string>(); // Initialize as needed
  const [imagesVisible, setImagesVisible] = useState(false);
  const [localSharedImages, setLocalSharedImages] = useState<cachedImage[]>(sharedImages);

  useEffect(() => {
    if (contactID !== null) {
      fetchContactImage(contactID);
      fetchDescription(contactID);
    } else {
      setContactProfileImage(undefined);
      setContactDescription("");
    }
  }, [contactID]);

  useEffect(() => {
    setLocalSharedImages(sharedImages);
  }, [sharedImages]);

  const fetchContactImage = async (contactID: number) => {
    try {
      const response = await axiosPrivate.get(`/api/get-profile-photo?userId=${contactID}`);
      if (response.data.imageUrl) {
        setContactProfileImage(response.data.imageUrl);
      } else {
        setContactProfileImage(undefined);
      }
    } catch (error) {
      console.error("Error fetching profile image:", error);
    }
  };

  const fetchDescription = async (contactId: number) => {
    try {
      const fetchedDescription = await handleGetDescription(contactId);
      setContactDescription(fetchedDescription);
    } catch (error) {
      console.error("Error fetching description:", error);
    }
  };

  const handleLogout = async () => {
    const rememberMe = localStorage.getItem("rememberMe") === "true"; // Convert the string back to a boolean

    try {
      await axiosPrivate.get(`/api/logout`, {
        params: { rememberMe, userID },
      });
      navigate("/login");
    } catch (error) {
      console.error("Failed to logout", error);
    }
  };

  const downloadImageAsJpeg = (imageData: string, fileName: string) => {
    try {
      // Convert Base64 image data to a Blob
      const byteString = atob(imageData); // Decode Base64 to binary string
      const mimeString = "image/jpeg"; // Assuming JPEG format
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const uintArray = new Uint8Array(arrayBuffer);

      // Fill Uint8Array with byteString values
      for (let i = 0; i < byteString.length; i++) {
        uintArray[i] = byteString.charCodeAt(i);
      }

      // Create a Blob from the ArrayBuffer
      const blob = new Blob([arrayBuffer], { type: mimeString });

      // Create a URL for the Blob
      const url = window.URL.createObjectURL(blob);

      // Create an anchor element and programmatically click it to download the file
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${fileName}.jpeg`);
      document.body.appendChild(link);
      link.click();

      // Clean up the URL and remove the anchor element
      link.parentNode?.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading the image:", error);
    }
  };

  return (
    <div className="details">
      <div className="user">
        {contactProfileImage ? <img src={contactProfileImage} className="contactImg" /> : <RxAvatar className="avatar-icon" />}
        <div className="details-contact-username">{contactUsername}</div>
        {contactDescription != "" ? <p className="scrollable-paragraph">{contactDescription}</p> : <p></p>}
      </div>
      <div className="info">
        <div className="option">
          <div className="title">
            <div className="option-title">Chat Settings</div>
            <MdKeyboardArrowUp className="optionArrow" />
          </div>
        </div>
        <div className="option">
          <div className="title">
            <div className="option-title">Privacy & Help</div>
            <MdKeyboardArrowUp className="optionArrow" />
          </div>
        </div>
        <div className="option">
          <div className="title">
            <div className="option-title">Shared Photos</div>
            {imagesVisible ? (
              <MdKeyboardArrowDown className="optionArrow" onClick={() => setImagesVisible(!imagesVisible)} />
            ) : (
              <MdKeyboardArrowUp className="optionArrow" onClick={() => setImagesVisible(!imagesVisible)} />
            )}
          </div>
          <div className={imagesVisible ? "photos margin-from-top" : "photos"}>
            {imagesVisible &&
              localSharedImages?.map((image, index) => (
                <div key={index} className="photoItem">
                  <div className="photoDetail" onClick={() => downloadImageAsJpeg(image.imageData || "", `photo_${image.date}`)}>
                    <img src={`data:image/jpeg;base64,${image.imageData}`} alt="" />
                    <IoMdDownload className="downloadArrow" />
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="option">
          <div className="title">
            <div className="option-title">Shared Files</div>
            <MdKeyboardArrowUp className="optionArrow" />
          </div>
        </div>
        <button>Block User</button>
        <button className="logout" onClick={handleLogout}>
          Logout
        </button>
      </div>
    </div>
  );
};

export default Details;
