import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2",
    },
    secondary: {
      main: "#dc004e",
    },
  },
  typography: {
    fontSize: 16,
    h5: {
      fontSize: "1.5rem",
    },
    body1: {
      fontSize: "1.2rem",
    },
  },
});

export default theme;
