import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import { useUser } from "./UserContext.tsx"; // Adjust import path

// Define the shape of your WebSocket context
interface WebSocketContextType {
  socket: WebSocket | null;
  sendMessage: (message: Message) => void;
}

interface Message {
  type: string;
  id?: number;
  sender_id: number;
  contact_id?: number | null;
  conversation_id?: number | null;
  content?: string;
  sent_at?: string;
}

// Create the WebSocket context
const WebSocketContext = createContext<WebSocketContextType | undefined>(undefined);

// Custom hook to use the WebSocket context
export const useWebSocket = (): WebSocketContextType => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error("useWebSocket must be used within a WebSocketProvider");
  }
  return context;
};

// WebSocketProvider component to wrap around your app
export const WebSocketProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { userID } = useUser(); // Assume useUser is a hook that provides user context
  const socketRef = useRef<WebSocket | null>(null);
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const [isReady, setIsReady] = useState<boolean>(false); // Track readiness

  useEffect(() => {
    // Only establish WebSocket connection if userID is available
    if (userID > 0) {
      const socketInstance = new WebSocket(getWebSocketUrl());

      socketRef.current = socketInstance;
      setSocket(socketInstance);

      // Set up event handlers
      socketInstance.onopen = () => {
        // Send a connection message when WebSocket connection is open
        const connectMessage: Message = {
          type: "connect",
          sender_id: userID,
        };
        sendMessage(connectMessage);
        console.log("WebSocket connection established");
        setIsReady(true); // Mark as ready when connection is established
      };

      socketInstance.onmessage = event => {
        console.log("Message from server:", event.data);
      };

      socketInstance.onclose = event => {
        console.log("WebSocket connection closed:", event);
        setIsReady(false); // Mark as not ready when connection is closed
      };

      socketInstance.onerror = error => {
        console.error("WebSocket error:", error);
        setIsReady(false); // Mark as not ready on error
      };

      // Cleanup function to close WebSocket connection on component unmount
      return () => {
        if (socketRef.current) {
          socketRef.current.close();
        }
      };
    }
  }, [userID]);

  // Define WebSocket URL based on the environment
  const getWebSocketUrl = () => {
    const isProduction = process.env.NODE_ENV === "production";
    const protocol = isProduction ? "wss" : "ws"; // Use 'wss' for secure WebSocket in production
    const host = isProduction ? "enginearme.xyz" : "localhost";
    const port = "8080"; // Assuming port is the same for both environments

    return `${protocol}://${host}:${port}/api/ws-conn`;
  };

  // Function to send a message
  const sendMessage = (message: Message) => {
    if (socketRef.current?.readyState === WebSocket.OPEN) {
      try {
        const messageString = JSON.stringify(message);
        socketRef.current.send(messageString);
      } catch (error) {
        console.error("Error sending message via WebSocket:", error);
      }
    } else {
      console.error("WebSocket is not open. ReadyState:", socketRef.current?.readyState);
    }
  };

  return <WebSocketContext.Provider value={{ socket, sendMessage }}>{children}</WebSocketContext.Provider>;
};
