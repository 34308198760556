import { axiosPrivate } from "../configuration/axiosConfig.ts";

export const handleChatPage = async () => {
  try {
    const response = await axiosPrivate.get("/chat");
    if (response.status === 200) {
    }
  } catch (err) {}
};

export const handlePersonSearch = async (searchQuery: string, username: string) => {
  try {
    // Pass searchQuery as a query parameter
    const response = await axiosPrivate.post("/api/searchPerson", {
      searchQuery: searchQuery,
      username: username, // Use 'params' to correctly format query parameters
    });
    if (response.status === 200) {
      return response.data; // Return the data from the response
    }
  } catch (err) {
    // Handle the error and provide feedback
    console.error("Error fetching search results:", err);
    throw new Error("Fetch from server failed."); // Re-throw the error or handle it as needed
  }
};

export const handleSetDescription = async (userId: number, description: string) => {
  try {
    // Pass searchQuery as a query parameter
    const response = await axiosPrivate.post("/api/set-description", {
      user_id: userId,
      description: description,
    });
    if (response.status === 200) {
      return response.data; // Return the data from the response
    }
  } catch (err) {
    // Handle the error and provide feedback
    console.error("Error fetching search results:", err);
    throw new Error("Fetch from server failed."); // Re-throw the error or handle it as needed
  }
};

export const handleGetDescription = async (userId: number) => {
  try {
    // Pass searchQuery as a query parameter
    const response = await axiosPrivate.get("/api/get-description", {
      params: { u: userId },
    });
    if (response.status === 200) {
      return response.data; // Return the data from the response
    }
  } catch (err) {
    // Handle the error and provide feedback
    console.error("Error fetching search results:", err);
    throw new Error("Fetch from server failed."); // Re-throw the error or handle it as needed
  }
};

export const handleGetConversationsInfo = async (username: string) => {
  try {
    // Pass searchQuery as a query parameter
    const response = await axiosPrivate.get("/api/getConversationsInfo", {
      params: { u: username }, // Use 'params' to correctly format query parameters
    });
    if (response.status === 200) {
      return response.data; // Return the data from the response
    }
  } catch (err) {
    // Handle the error and provide feedback
    console.error("Error fetching search results:", err);
    throw new Error("Fetch from server failed."); // Re-throw the error or handle it as needed
  }
};

export const handleGetCurrentConversation = async (username: string) => {
  try {
    // Pass searchQuery as a query parameter
    const response = await axiosPrivate.get("/api/getCurrentConversation", {
      params: { u: username }, // Use 'params' to correctly format query parameters
    });
    if (response.status === 200) {
      return response.data; // Return the data from the response
    }
  } catch (err) {
    // Handle the error and provide feedback
    console.error("Error fetching search results:", err);
    throw new Error("Fetch from server failed."); // Re-throw the error or handle it as needed
  }
};

export const handleAddFriend = async (requester_user_id: number, recipient_user_id: number) => {
  try {
    const response = await axiosPrivate.post("/api/add-friend", { requester_user_id: requester_user_id, recipient_user_id: recipient_user_id });
    if (response.status === 200) {
      console.log(response.data);
      return response.data;
    }
  } catch (error) {
    console.error("Error adding contact:", error);
  }
};

export const handleDeleteFriendRequest = async requesterUserID => {
  try {
    console.log("Deleting friend request for user ID:", requesterUserID);
    const response = await axiosPrivate.get("/api/delete-friend-request", {
      params: { requesterUserID }, // Parameter name must match the backend expectation
    });

    if (response.status === 200) {
      console.log("Friend request deleted:", response.data); // Handle success response
      return response.data;
    } else {
      console.error("Unexpected response status:", response.status);
    }
  } catch (error) {
    console.error("Error deleting friend request:", error);
    // Optionally, handle the error further based on your requirements
  }
};

export const handleAcceptFriendRequest = async (requester_user_id: number, recipient_user_id: number) => {
  try {
    const response = await axiosPrivate.post("/api/accept-friend-request", { requester_user_id: requester_user_id, recipient_user_id: recipient_user_id });
    if (response.status === 200) {
      console.log(response.data);
      return response.data;
    }
  } catch (error) {
    console.error("Error adding contact:", error);
  }
};

export const handleBlockRequest = async (requester_user_id: number) => {
  try {
    const response = await axiosPrivate.get("/api/block-request", { params: { r: requester_user_id } });
    if (response.status === 200) {
      console.log(response.data);
      return response.data;
    }
  } catch (error) {
    console.error("Error adding contact:", error);
  }
};
