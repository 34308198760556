import React, { createContext, useState, useEffect, ReactNode } from "react";
import { axiosPrivate } from "../../configuration/axiosConfig.ts";

interface UserContextType {
  username: string;
  userID: number;
  profileImage: string | null; // Add profile image
  setProfileImage: (imageUrl: string) => void; // Function to update profile image
}

const defaultContextValue: UserContextType = {
  username: "",
  userID: 0,
  profileImage: null,
  setProfileImage: () => {}, // Default to a no-op function
};

const UserContext = createContext<UserContextType>(defaultContextValue);

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const [username, setUsername] = useState<string>("");
  const [userID, setUserID] = useState<number>(0);
  const [profileImage, setProfileImage] = useState<string | null>(null); // Initialize as needed

  const [loading, setLoading] = useState<boolean>(true);

  const value = {
    username,
    userID,
    profileImage,
    setProfileImage, // Provide function to update profile image
  };

  useEffect(() => {
    const fetchUsername = async () => {
      try {
        const response = await axiosPrivate.get("/api/getUsername", {});
        setUsername(response.data.username);
      } catch (error) {
        console.error("Failed to fetch username", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsername();
  }, []);

  useEffect(() => {
    const fetchUserID = async () => {
      try {
        const response = await axiosPrivate.get("/api/getUserID", {});
        setUserID(response.data.userID);
      } catch (error) {
        console.error("Failed to fetch userID", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserID();
  }, []);

  useEffect(() => {
    const fetchProfileImage = async () => {
      try {
        const response = await axiosPrivate.get(`/api/get-profile-photo?userId=${userID}`);
        if (response.data.imageUrl) {
          setProfileImage(response.data.imageUrl);
        }
      } catch (error) {
        console.error("Error fetching profile image:", error);
      }
    };

    fetchProfileImage();
  }, []);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUser = () => React.useContext(UserContext);
