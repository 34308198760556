import React, { useState, useEffect } from "react";
import "./UserInfoModal.css"; // Add necessary styles
import { FaXmark } from "react-icons/fa6";
import { handleGetDescription } from "../../../handlers/ChatPageHandlers.tsx";

interface UserInfoModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (description: string) => void;
  userId: number; // Pass userId to fetch description
}

const DescriptionModal: React.FC<UserInfoModalProps> = ({ isOpen, onClose, onSubmit, userId }) => {
  const [description, setDescription] = useState("");

  useEffect(() => {
    if (isOpen) {
      const fetchDescription = async () => {
        try {
          const fetchedDescription = await handleGetDescription(userId);
          setDescription(fetchedDescription);
        } catch (error) {
          console.error("Error fetching description:", error);
        }
      };

      fetchDescription();
    }
  }, [isOpen, userId]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(description);
    setDescription(""); // Clear description after submission
    onClose(); // Close modal after submission
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay-user">
      <div className="modal-content-user">
        <button className="modal-close-user" onClick={onClose}>
          <FaXmark />
        </button>
        <div className="update-description">Update Description</div>
        <form onSubmit={handleSubmit}>
          <textarea value={description} onChange={e => setDescription(e.target.value)} placeholder="Enter your description" rows={4} />
          <button className="submitButton-user" type="submit">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default DescriptionModal;
