import React, { useState } from "react";
import UserInfo from "./UserInfo/UserInfo.tsx";
import ChatList from "./ChatList/ChatList.tsx";
import SearchSection from "./SearchSection/SearchSection.tsx";

import "./List.css";

const List = ({ onCurrentConversationIDChange, onCurrentContactIDChange, onCurrentContactUsernameChange, updateChatListFlag, onSelectConversation }) => {
  // State to track if the user has typed anything in the search bar
  const [isSearching, setIsSearching] = useState(false);
  const [searchDebounceLoading, setSearchDebounceLoading] = useState(false);

  // State to track the search query
  const [searchQuery, setSearchQuery] = useState("");
  const [searchText, setSearchText] = useState("");

  const handleCurrentConversationIDChange = conversationID => {
    onCurrentConversationIDChange(conversationID);
  };

  const handleCurrentContactIDChange = contactID => {
    onCurrentContactIDChange(contactID);
  };

  const handleCurrentContactUsernameChange = contactID => {
    onCurrentContactUsernameChange(contactID);
  };

  // Handler for search input change
  const handleSearchChange = query => {
    setSearchQuery(query);
    setIsSearching(query.length > 0);
  };

  const contactClick = () => {
    onSelectConversation();
  };
  const clearSearchQuery = () => {
    setSearchQuery("");
    setIsSearching(false);
  };

  return (
    <div className="list">
      <UserInfo></UserInfo>
      <SearchSection onSearchChange={handleSearchChange} setSearchDebounceLoading={setSearchDebounceLoading} />
      <ChatList
        updateChatListFlag={updateChatListFlag}
        isSearching={isSearching}
        searchQuery={searchQuery}
        searchDebounceLoading={searchDebounceLoading}
        onCurrentConversationChange={handleCurrentConversationIDChange}
        onCurrentContactChange={handleCurrentContactIDChange}
        onCurrentContactUsernameChange={handleCurrentContactUsernameChange}
        onContactClick={clearSearchQuery}
        onContactClickFromContactList={contactClick}
      />
    </div>
  );
};

export default List;
